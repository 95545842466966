var button_text = ''
$(function() {
  $("#show-more-mem").on('click', function() {
    $('#past-memberships').toggle();
    if ($("#show-more-mem").text().includes('More')) {
      button_text = $("#show-more-mem").text()
      $("#show-more-mem").text("Hide History...")
    } else {
      $("#show-more-mem").text(button_text)
    }
  });
});